module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"81ea3bc6-704f-5b18-8c1b-93f74ada6065","name":"gatsby-remark-images","version":"3.1.35","pluginOptions":{"plugins":[],"maxWidth":700,"linkImagesToOriginal":false,"showCaptions":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{"default":"/opt/build/repo/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},"gatsby-remark-relative-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"linkImagesToOriginal":false,"showCaptions":true}},{"resolve":"gatsby-remark-embed-video","options":{"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":false,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Slyvon Blanco","short_name":"@slyvon","start_url":"/","background_color":"#f7f7f7","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
